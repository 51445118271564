 .permission-lookup-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
   
   .permission-form-control {
     display: flex;
     flex-direction: row;
     width: 50%;
     margin: 1em 0 1em 0;
     flex: 1;

     .serial-number-input {
       flex: 1;
     }
     
     .search-button {
       margin: 0.5em;
     }
   }
   
   .results-surface {
     width: 95%;
     flex: 1;
     padding: 1em;
     
     .org-item {
       display: flex;
       flex-direction: row;
       
       .org-name {
         margin: 0.3em;
         font-size: medium;
       }
     }
   }
}