.compressor-resolved-alerts-search-form-container {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
    justify-content: flex-start;
    align-items: baseline;
    font-weight: 500;
    font-size: 14px;
}

.compressor-resolved-alerts-search-form-container > * {
    margin-left: 15px;
}

.get-compressor-alerts-button {
    margin-left: 15px;
}