@import "src/styles/utilities";

@mixin telemetry-data-container-small {
  flex-direction: column;
}

@mixin compressor-diagram-small {
  position: relative;
  padding-bottom: 10%;
}

.telemetry-data-container {
  display: flex;
  margin-top: 1em;
  gap: 1em;

  @include sm {
    @include telemetry-data-container-small
  }
  @include xs {
    @include telemetry-data-container-small
  }

  .compressor-diagram {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    position: sticky;
    top: 0%;
    flex: 1;

    @include sm {
      @include compressor-diagram-small;
    }
    @include xs {
      @include compressor-diagram-small;
    }

    .diagram-instructions {
      padding: 1em;
      color: rgba(0, 0, 0, .64);
      text-align: center;
    }
  }

  .throw-telemetry-data-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex: 1;
  }

  .frame-telemetry {
    flex: 1;
  }
}
