.temperature-gauge-units {
    font-size: 10px;
    line-height: 20px;
    vertical-align: super;
}

.temperature-gauge-value {
    font-size: 18px;
    font-weight: 500;
}

.temperature-gauge-value-container {
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
}

.temperature-gauge-subtitle {
    color: rgba(0, 0, 0, .54);
}