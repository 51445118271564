@import '/src/styles/utilities';

.lube-oil-cards-container {
  @include responsive-grid(7);
  margin-top: 1em;
  @include sm {
    grid-column-gap: 0;
  }
  .col-span-2 {
    grid-column: span 2;
  }
  .rpm-card{
    font-size: 1.5em;
    text-align: center;
    margin: auto;
    padding-bottom: 1em;
  }
}