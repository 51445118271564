.unloader-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: .5em;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .unloader-data {
    font-weight: 500;
  }

  img:hover {
    opacity: .8;
  }
}

.unloader-container.selected {
  :hover {
    opacity: 1;
  }
}

.unloader-container.disabled {
  :hover {
    opacity: 1;
  }

  cursor: default;
}

.even-throw.unloader-container {
  justify-content: flex-start;

  img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}