@use "sass:math";

@mixin flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  :not(:last-child) {
    margin-right: 1em;
  }
}

@mixin responsive-grid($columns: 6) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  column-gap: 1em;
  row-gap: 1em;

  @include xs {
    grid-template-columns: repeat(calculateColumns($columns, 6), 1fr);
  }
  @include sm {
    grid-template-columns: repeat(calculateColumns($columns, 4), 1fr);
  }
  @include md {
    grid-template-columns: repeat(calculateColumns($columns, 3), 1fr);
  }
  @include lg {
    grid-template-columns: repeat( calculateColumns($columns, 2), 1fr);
  }
}

@function calculateColumns($columns, $divisor) {
  @return math.max(math.floor($columns / $divisor), 1);
}


$size-sm: 600px;
$size-md: 900px;
$size-lg: 1200px;
$size-xl: 1536px;

@mixin xs {
  @media (max-width: #{$size-sm - 1px}) {
    @content
  }
}

@mixin sm {
  @media (min-width: #{$size-sm}) and (max-width: #{$size-md - 1px}) {
    @content
  }
}

@mixin md {
  @media (min-width: #{$size-md}) and (max-width: #{$size-lg - 1px}) {
    @content
  }
}

@mixin lg {
  @media (min-width: #{$size-lg}) and (max-width: #{$size-xl - 1px}) {
    @content
  }
}

@mixin xl {
  @media (min-width: #{$size-xl}) {
    @content
  }
}