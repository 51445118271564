.reports-landing-page-container {
  .e-tab-header {
    background-color: white;
    border-bottom: 1px solid #ddd;
  }

  .e-tab .e-content {
    padding: 0em 1em 1em 1em;
  }
}

.report-landing-tabs {
  margin: 1em 0em 1em 0em;
}