.chart-skeleton-container {
  padding-top: 5px;
  width: 90%;
  height: 80%;
  display: flex;
}

.chart-skeleton-left {
  flex: 1;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  min-width: 30px;
}

.chart-skeleton-right {
  flex: 10;
}

.chart-skeleton-x-labels {
  display: flex;
  justify-content: space-evenly;
}

.grid-skeleton-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 2% 0 2%;
  margin: auto;
  justify-content: space-evenly;
  padding-top: 20px;
}

.grid-skeleton-row {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
}

.grid-skeleton-cell {
  height: 70%;
  width: auto;
  margin: 0 15px;
}

.grid-skeleton-header {
  margin-bottom: 15px;
}

.text-skeleton-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 2% 0 2%;
  margin: auto;
  justify-content: space-evenly;
  padding-top: 20px;
  gap: 1em;
}

.list-item-skeleton {
  display: flex;
  height: 5em;
  width: 100%;
  align-items: center;
  gap: 1em;
  padding: 1em;

  .list-item-text {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}