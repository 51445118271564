.org-management-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 1em;
  align-items: center;

  .select-parent-organization {
    width: 50%;
  }

  .hierarchy-container {
    padding: 1em;
    max-height: 70vh;
    overflow-y: scroll;

    .e-treeview {
      overflow-y: scroll;
    }
  }

  .tree-node {
    display: flex;
    gap: 1em;
    align-items: center;

    .organization {
      font-size: 1.25em;
      font-weight: 500;
    }

    .compressor {
      font-size: 1.25em;
    }
  }

  .e-list-icon {
    display: none;
  }

}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: #0072c6;
}
