@import "src/styles/utilities";

.compressor-information-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  line-height: 1.25em;
  font-size: .8em;

  .compressor-nickname {
    line-height: 1.25em;
    font-size: 1.5em;
    font-weight: bold;
  }

  .primary-information {
    display: flex;
    flex-direction: row;

    .status-icon-container {
      height: 5em;
      width: 5em;
      padding: 1em;

      @media(max-width: 899px) {
        display: none;
      }
    }
  }
}


.compressor-information-category-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    gap: 1em;

    span {
      font-weight: 600;
    }
  }
}