.vibration-tab-content{
  padding: 1em 0em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  
  .vibration-table-card{
    .title{
      font-size: 1.25em;
      color: rgba(0, 0, 0, 0.54)
    }
    
    padding: 1em 1.5em;
    flex-direction: column;
    
    .footer-text{
      padding-top: .5em;
      font-size: .75em;
      color: rgba(0, 0, 0, 0.54)
    }
  }
  
  .vibration-skeleton{
    tr{
      td{
        padding: .6em 1em;
      }
      td:nth-child(4),
      {
        display: flex;
        justify-content: center;
      }
    }
  }
}