.phone-validation-view {
  min-width: 25em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .buttons-container {
    align-self: flex-end;
  }

  .step-container {
    display: flex;
    flex-direction: column;
  }
}