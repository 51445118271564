@import "../../styles/colors.scss";

.terms-and-conditions {
  margin: 2em 10vw;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .terms-text {
    padding: 1em 2em;
    max-height: 60vh;
    overflow-y: scroll;
    border: solid 1px $ariel-input-underline-grey;
    border-radius: 5px;
    background-color: #f0f0f0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .buttons-container {
    margin-top: 1em;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 1em;
    width: 20em;

    a {
      display: flex;
      justify-content: center;
      text-decoration: none;
    }
  }
}