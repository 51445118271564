@import "src/styles/colors.scss";

.compressor-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20em;
  max-height: 75vh;
  overflow-y: auto;

  .header-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
  }
  .header {
    margin: .5em 1em;
    justify-self: center;
  }

  .close-button {
    margin: .5em;
    justify-self: end;
  }

  .favorite-toggle-buttons {
    padding: .75em 1em;
  }

  .filter-accordion {
    width: 100%;

    .MuiAccordionSummary-content {
      margin: .5em;
    }

    .filter-accordion-header {
      display: flex;
      flex-direction: column;
      margin-right: 2em;
      width: 100%;

      .filter-accordion-title-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status-checkbox-form {
    margin-left: 1.5em;

    .status-checkbox {
      margin-right: .5em;
      display: flex;
      align-items: center;

      .status-color {
        width: 1em;
        height: 1em;
        border-radius: .5em;
      }

      .shutdown-fault {
        background-color: $ariel-red;
      }

      .warning {
        background-color: $ariel-yellow;
      }

      .ok {
        background-color: $ariel-green;
      }

      .normal-shutdown {
        background-color: black;
      }

      .disconnected {
        background-color: lightgray;
      }
      
      .running-shutdown-fault {
        background-color: $ariel-orange
      }

      .unknown, .non-asc {
        background-color: white;
        border: solid 1px black;
      }
    }
  }
}

.confirmation-button-container {
  width: 90%;
  margin: 1em;
}