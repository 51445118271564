@import "src/styles/colors.scss";
@import "src/styles/utilities";

.valve-temp-container {
  grid-column: span 6;
  margin: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: hidden;

  .valve-data-container {
    padding: 1em;
    display: flex;
    flex-direction: column;

    .valve-temp-graph-header {
      display: flex;
      margin: 0em 1em;
      justify-content: space-between;
      flex-direction: row;

      @include sm {
        flex-direction: column;
        align-content: center;
      }

      .valve-leak-labels-container {
        display: flex;
        gap: 1em;
        cursor: pointer;

        @include sm {
          position: absolute;
          right: 1.5em;
        }

        .critical-leaks-label {
          color: $ariel-red;
          text-decoration: underline;
        }

        .moderate-leaks-label {
          color: $ariel-yellow;
          text-decoration: underline;
        }
      }
    }

    .valve-temp-chart-legend {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      gap: 0.75em;

      .faded {
        opacity: .3;
      }

      .suction-valve-temp-legend {
        width: 1em;
        height: 1em;
        background-color: $ariel-navy;
      }

      .discharge-valve-temp-legend {
        width: 1em;
        height: 1em;
        background-color: #aaa;
      }
    }
  }
}