@import "src/styles/utilities";

.compressor-landing-chart-tab {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding-top: 1em;
  height: 80vh;
  gap: 10px;

  .chart-display {
    display: flex;
    flex-direction: column;
    align-items: center;

    .chart-options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1em 1em 0 1em;
      width: 100%;

      .left-item {
        width: 10em;
      }
      .chart-settings-options {
        display: flex;
        align-items: center;
        gap: 2.5em;

        .switch-container {
          display: flex;
          gap: .5em;
          align-items: baseline;
        }
      }
    }

    .custom-date-inputs {
      display: flex;
      gap: .5em;

      .right-item {
        margin-left: 2em;
      }
    }

    .duration-dropdown {
      margin-left: auto;
    }

    .chart-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}