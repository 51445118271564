.fleet-resolved-alerts-search-form-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    font-weight: 500;
    font-size: 14px;
    margin: 0 15px;
}

.fleet-resolved-alerts-search-form-container > * {
    margin-right: 15px;
}

.get-alerts-button {
    margin-left: 15px;
}