.MuiChartsTooltip-root {
  opacity: 0.8;

  .MuiChartsTooltip-table {
    td {
      background-color: #000;
      color: #ffffff;
    }

    .MuiChartsTooltip-labelCell {
      background-color: #000;
      color: #ffffff;
    }

    .MuiChartsTooltip-valueCell {
      background-color: #000;
      color: #ffffff;
    }

    .MuiChartsTooltip-markCell {
      background-color: #000;
    }
  }
}
