.header-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
}

.close-button {
  margin: .5em;
  justify-self: end;
}

.compressor-list-item {
  height: 5em;

  .secondary-container {
    display: flex;
    flex-direction: column;
  }
}