@import 'src/styles/colors.scss';

.valve-status-normal {
  @include filter-ariel-green;
}

.valve-status-moderate {
  @include filter-ariel-yellow;
}

.valve-status-critical {
  @include filter-ariel-yellow;
}

.valve-status-disconnected {
  border-radius: 50%;
  background-color: lightgray;
}

.valve-status-icon {
  width: 2em;
  height: 2em;
}