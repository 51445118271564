@import "src/styles/utilities";

.dashboard-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .value-prop-container {
    padding: 1em 1.5em;
    display: flex;
    flex-direction: column;
    gap: .75em;
    justify-content: flex-start;
    width: 100%;

    @include xs {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      padding: 1em 1.5em;
    }
    @include sm {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      padding: 1em 1.5em;
    }
  }

  .value-prop-value {
    font-size: 1.5em;
    color: #000;
  }
}

.multi-value-dashboard-card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 1.5em;
  justify-content: flex-start;

  .title-container {
    display: flex;
    justify-content: space-between;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.54);

    #dashboard-card-button {
      border: none;
      background: transparent;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .multi-value-container {
    display: flex;
    justify-content: space-around;

    .value-prop-container {
      padding: 0em 1.5em;
      display: flex;
      flex-direction: column;
      gap: .75em;
      align-items: center;
    }

    .value-prop-value {
      font-size: 1.5em;
      color: #000;
    }
  }
}

