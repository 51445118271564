.fault-trouble-shooting-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
  padding: 1em;
  height: 95%;

  hr {
    background: lightgray;
  }

  .fault-list-container{
  width: 30%;
  padding: 1em;
  display: flex; 
  flex-direction: column;
  .fault-list {
    margin-top: 0.5em;
    overflow: auto;
    max-height: 95%;
  }
  
  .search-bar {
    width: 100%;
    margin-bottom: 0.5em;
  }  
}
  .fault-troubleshooting-detail-container {
    width: 70%;
    padding: 1em;
    max-height: 100%;
    overflow: auto;
    
    .detail {
      margin-left: 1em;
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: 0.5em;
    }
    
    hr {
      margin-bottom: 1em;
    }
  }
}