@import "src/styles/utilities";

.conditions-summary-container {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;


  .conditions-stage {
    grid-column-end: span 2;
  }

  .stages-container {
    margin-top: 1em;
    @include responsive-grid(6);

    .value-not-received-container {
      min-height: 10em;
      line-height: 1em;
      display: flex;
    }
  }

  .stages-container .e-card {
    margin-right: 1em;
  }

  .operation-statistics-container {
    @include responsive-grid(5);
  }

  .temperature-gauge-row {
    display: flex;
    margin-top: -30px;
  }

  .gauge-skeleton-row {
    display: flex;
    align-items: center;
  }

  .skeleton-container {
    padding-top: 1.5em;
  }

  .gauge-skeleton-row > * {
    margin: 1em;
  }
}