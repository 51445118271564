@import "src/styles/utilities";

.valve-leak-tab-container {
  grid-column: span 3;
  margin: 1em 0em;
  display: flex;
  position: sticky;
  top: 0;
  height: 90vh;

  td.e-rowcell {
    padding: .5em;
  }

  valve-status-icon {
    width: 1em;
    height: 1em;
  }

  .e-grid {
    .e-rowcell:last-child, .e-rowcell:first-child {
      padding-right: .5em;
    }
  }
}
