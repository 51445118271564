@import "src/styles/colors.scss";

.add-user-landing-container {
  display: flex;
  flex-direction: column;
  margin: 1em;

  .add-user-form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .add-user-form-single-item {
    display: flex;
    flex-direction: row;
  }

  .available-users-container {
    width: 55%;
    max-height: 40em;
  }

  .new-user-form-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
    height: 28em;
  }

  .new-user-form-fields {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .new-user-form-button-container {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin: 1em;
  }

  .e-btn {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  hr {
    margin-bottom: 2em;
  }

  h6 {
    font-weight: 600;
  }

  input[type=text] {
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid $ariel-input-underline-grey;
    font-size: 13px;
  }

  input[type=text]:focus-within {
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid $ariel-blue;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }

  a:hover {
    color: #ffffff;
  }
}