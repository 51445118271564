.pressure-gauge-units {
    font-size: .8rem;
}

.pressure-gauge-value {
    font-size: 18px;
    line-height: 0px;
    font-weight: 500;
}

.pressure-gauge-value-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.pressure-gauge-title {
    font-size: 1rem;
    color: rgba(0, 0, 0, .54);
    text-align: center;
}

.pressure-gauge-subtitle {
    color: rgba(0, 0, 0, .54);
}