.alert-selection-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .alerts-grid-container{
    height: 80%;
  }
  .buttons-container{
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: flex-end;
    
    .confirm-button{
      align-self: flex-end;
    }
  }
}