
.compressor-landing-alert-tabs {
  margin: 1em 0;
}

.compressor-landing-page-container {
  width: 100%;

  .e-tab-header {
    background-color: white;
    border-bottom: 1px solid #ddd;
  }

  .e-tab > .e-content {
    padding: 0em 1em 1em 1em;
  }
  
  .compressor-landing-outlet{
    padding: 0em 1em 1em 1em;
  }
}

.compressor-landing-header {
  padding: .5em 1em 0em 1em;
  background-color: white;
}