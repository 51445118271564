@import 'src/styles/colors.scss';

.status-icon-ok {
  @include filter-ariel-green;
}

.status-icon-active-warning {
  @include filter-ariel-yellow;
}

.status-icon-normal-shutdown {
  border-radius: 50%;
  background-color: black;
}

.status-icon-shutdown-fault {
  @include filter-ariel-red;
}

.status-icon-non-asc {
  border-radius: 50%;
  background-color: white;
  border: solid 1px black;
}

.status-icon-disconnected {
  border-radius: 50%;
  background-color: lightgray;
}

.status-icon-running-shutdown {
  @include filter-ariel-orange;
}

.status-icon-base {
  width: 100%;
  height: 100%;
}