$ariel-green: #497b37;
$ariel-red: #af3219;
$ariel-yellow: #f9ae33;
$ariel-blue: #0072c6;
$ariel-navy: #003e70;
$ariel-input-underline-grey: #9e9e9e;
$ariel-orange: #f26700;

@mixin filter-ariel-green {
  filter: invert(48%) sepia(6%) saturate(4069%) hue-rotate(60deg) brightness(81%) contrast(77%);
}

@mixin filter-ariel-red {
  filter: invert(28%) sepia(36%) saturate(2988%) hue-rotate(344deg) brightness(87%) contrast(101%);
}

@mixin filter-ariel-yellow {
  filter: invert(86%) sepia(25%) saturate(3681%) hue-rotate(333deg) brightness(100%) contrast(96%);
}

@mixin filter-ariel-orange {
  filter: invert(46%) sepia(80%) saturate(2404%) hue-rotate(1deg) brightness(94%) contrast(106%)
}