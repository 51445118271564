@import "src/styles/colors.scss";

.compressor-list {
  width: 100%;
  min-height: 100%;
  background: white;
  border-right: solid 1px #ccc;

  .compressor-status-icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    align-self: center;
    margin-right: 1em;
  }

  .search-and-filter {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: white;
    z-index: 99;
    display: flex;
    flex-direction: column;
    padding: .61em 1em 1em 1em;
    gap: .5em;
    border-bottom: solid 2px $ariel-input-underline-grey;

    .search-bar-container {
      display: flex;
      justify-content: space-between;

      .filter-button {
        display: flex;
        gap: .25em;
      }
    }
  }
}