@import './syncfusion/ej2/material.css';


.e-dashboardlayout.e-control .e-panel {
  border-radius: 5px;
}

.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
  color: #333;
}

#root,
html,
body,
.app-container {
  box-sizing: border-box;
  height: 100vh;
  background-color: #f8f8f8;
  width: 100vw;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.main-content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.e-card {
  border-radius: 5px;
}

.e-card:hover {
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 16%);
}

.e-card .e-card-header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: normal;
  min-height: 22.5px;
  padding: 1em;
  width: inherit;
  font-weight: 500;
}

@media screen and (max-width: 899px) {
  .flex-sm, .flex-md, .flex-lg, .flex-xl {
    flex: 1 0 100%;
  }
}

@media screen and (min-width: 900px) {
  .flex-sm {
    flex: 1 0 50%;
  }

  .flex-md, .flex-lg, .flex-xl {
    flex: 1 0 100%;
  }
}

@media screen and (min-width: 1200px) {
  .flex-sm {
    flex: 1 0 25%;
  }

  .flex-md {
    flex: 1 0 50%;
  }

  .flex-lg, .flex-xl {
    flex: 1 0 100%;
  }
}

@media screen and (min-width: 1536px) {
  .flex-sm {
    flex: 1 0 12.5%;
  }

  .flex-md {
    flex: 1 0 25%;
  }

  .flex-lg {
    flex: 1 0 50%;
  }

  .flex-xl {
    flex: 1 0 100%;
  }
}

h3 {
  font-size: 1.25em;
  font-family: Gotham A, Gotham B, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: Gotham A, Gotham B, Helvetica, Arial, sans-serif;;
}