#alertsGrid .severity-cell.severity-cell-warning {
    background-color: #F9AE33;
    color: white;
}

#alertsGrid .severity-cell.severity-cell-shutdown {
    background-color: #AF3219;
    color: white;
}

#alertsGrid .severity-cell.severity-cell-na {
    background-color: #808080;
    color: white;
}

#alertsGrid .severity-cell[aria-selected="true"] {
    filter: brightness(85%);
}

#alertsGrid .e-row:hover .severity-cell {
    filter: brightness(85%);
}