@import "src/styles/utilities";

.lube-oil-chart-container {
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  width: 100%;
  height: 70vh;
  padding: 1em;
  padding-bottom: 0;
  
  .lube-oil-chart-title {
    align-self: flex-start;
    padding-left: 1em;
  }
}