.compressors-page {
  display: flex;
  height: 100%;

  .navigation {
    position: sticky;
    overflow-y: scroll;
    width: 25em;
  }

  .detail {
    display: flex;
    overflow-y: scroll;
    flex: 1;
  }
}


