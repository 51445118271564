.frame-container {
  position: relative;
  cursor: pointer;

  .frame-data {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    color: white;
    font-weight: 500;
  }

  :hover {
    opacity: .8;
  }
}

.frame-container.selected {
  :hover {
    opacity: 1;
  }
}

.frame-container.disabled {
  :hover {
    opacity: 1;
  }

  cursor: default;
}