@import "src/styles/colors.scss";

.variable-display-container {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  height: 80vh;

  .variable-button-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    padding: 5px;
  }

  .variable-search-container {
    padding: 5px 5px 5px 5px;
    //border-bottom: solid 2px $ariel-input-underline-grey;
  }

  .variable-chip-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    border-bottom: solid 2px $ariel-input-underline-grey;
    max-height: 20vh;
  }

  .variable-list {
    overflow-y: auto;
  }
}

.compressor-variable-chart {
  width: 100%;
}