.axis-chooser {
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 1em;
  align-tems: center;
  width: 100%;
  height: 100%;
  
  
  .axis-chooser-header{
    display: flex;
    gap: 5em;
    justify-content: space-around;
  }
  .list-container{
    display: flex;
    width: 100%;
    flex: 1;
    gap: 1em;
    align-items: center;
    height: 90%;
    
    .transfer-list{
      height: 100%;
      overflow: scroll;
      border: 1px solid lightgray;
      border-radius: 5px;
      flex: 1
    }
  }
}