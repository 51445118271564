.compressor-notification-rules {

  .notification-grid {
    width: 100%;
  }

  .notification-form-row {
    padding: 1em;
    align-items: center;
    display: flex;
    justify-content: space-around;
  }

  .notification-preferences {
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
  }

  .bold {
    font-weight: bold;
  }
}