#main-appbar {
    background-color: #0072c6;
    padding: 0 .5em;
    position: sticky;
    
    .appbar-toolbar {
        min-height: 50px;
    }
}

#main-appbar a {
    color: white;
    text-decoration: none;
}

#main-appbar button:last-child {
    margin-left: auto;
}